import React from 'react';
import { Container } from 'react-grid-system';
import styled, { ThemeProvider } from 'styled-components';
import { light } from 'themes';
import Layout from 'components/Layout';

const Text = styled.p`
  font-size: 50px;
  text-align: center;
  margin-top: 50px;
`;

function NotFoundPage() {
  return (
    <ThemeProvider theme={light}>
      <Layout>
        <Container>
          <Text>Page not found</Text>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

export default NotFoundPage;
